const isBrowser = typeof window !== `undefined`

const getUser = () =>
  window.localStorage.gatsbyUser
    ? JSON.parse(window.localStorage.gatsbyUser)
    : {}

const setUser = user => (window.localStorage.gatsbyUser = JSON.stringify(user))

export const handleLogin = ({ password, terms }) => {
  if (!isBrowser) return false

  if (password === process.env.GATSBY_SITE_PASSWORD && terms === true) {
    return setUser({
      termsAccepted: true
    })
  }
  return false
}

export const isLoggedIn = () => {
  if (!isBrowser) return false

  const user = getUser()

  //returns true vaLue of terms have been accepted by user
  return !!user.termsAccepted
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = callback => {
  if (!isBrowser) return

  console.log(`Ensuring the \`gatsbyUser\` property exists.`)
  setUser({})
  callback()
}
