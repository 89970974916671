import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Login from "../components/Pages/LogIn/LogIn"
import { isLoggedIn } from "../utils/auth"

const IndexPage = () => {

  //need to use useEffect to prevent window not being defined on server side rendering error
  //to use useEffect React expects a capitalized function
  function InitiateNavigate() { 
    useEffect (() => navigate("/app/home")) 
  }

  return (
  <>
    { !isLoggedIn ? 
      <Login /> 
      : InitiateNavigate()
    }
  </>
  )
}

export default IndexPage