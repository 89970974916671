import React, { useState } from "react"
import { navigate, graphql, useStaticQuery } from "gatsby"
// import { navigate } from "@reach/router"
import { handleLogin, isLoggedIn } from "../../../utils/auth"
import styles from "./LogIn.module.css"

//any extra info is stored in location.state by the navigate function
//and passed to the page it is navigating to
const Login = ({ location }) => {

  const data = useStaticQuery(graphql`
    {
  markdownRemark(id: {eq: "e3601c25-5c2b-514f-86a8-b0fd56bd6825"}) {
    html
  }
}
  `
  )
  const termsHtml = data.markdownRemark.html

 const [user, setUser] = useState({
   password: ``,
   terms: ``
 });
 
 const [isChecked, setIsChecked] = useState(false);
 
 const handleChecked = e => {
   setIsChecked(e.currentTarget.checked)
   return setUser({
     ...user,
     terms: e.currentTarget.checked
    }
    )
  }

  const handleUpdate = e => {
    setUser({
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    handleLogin(user)
    if(isLoggedIn()){
      navigate(typeof location === "undefined" ? "/app/home" : location.state.destination)
    }
  }           
      return (
        <div className={styles.layout}>
          <div className={styles.container}>
            <h1>
              <em>Welkom!</em>
            </h1>
            <div>
              This website is a free resource for those learning the Low German
              language. Please enter the password and accept the terms and
              conditions to enter the site. If you think you should have access
              to the site, please send our team an email at{" "}
              <a className={styles.link} href="pdtwieedunsaza@gmail.com">
                pdtwieedunsaza@gmail.com
              </a>
              . Happy learning!
            </div>
            <form
              method="post"
              onSubmit={e => {
                handleSubmit(e)
                navigate(
                  typeof location === "undefined"
                    ? "/app/home"
                    : location.state.destination
                )
              }}
            >
              {/* <label>
            Username
            <input type="text" name="username" onChange={this.handleUpdate} />
          </label> */}
              <label>
                <strong>Password:</strong>
                <input
                  className={styles.input}
                  type="password"
                  name="password"
                  onChange={e => handleUpdate(e)}
                />
              </label>
              <div
                className={styles.terms}
                dangerouslySetInnerHTML={{ __html: termsHtml }}
              />
              <div className={styles.buttonWrapper}>
                <label className={styles.buttonNormal}>
                  I have read and accept the terms and conditions
                  <input
                    className={`${styles.input}`}
                    type="checkbox"
                    name="checkbox"
                    onChange={e => handleChecked(e)}
                    checked={isChecked}
                  />
                </label>
                <input
                  className={`${styles.buttonLogin}`}
                  type="submit"
                  value="Log In"
                />
              </div>
            </form>
            <div className={styles.attribution}>
              Favicon by{" "}
              <a
                href="https://www.flaticon.com/authors/pixel-buddha"
                title="Pixel Buddha"
              >
                Pixel Buddha
              </a>{" "}
              from{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>
            </div>
          </div>
        </div>
      )
  }

export default Login
